import React from 'react';
import { Compass, Map, Route, UserCheck } from 'lucide-react';

const ServiceItem = ({ icon: Icon, title, description, bgImage, color }) => (
  <div className={`relative overflow-hidden rounded-lg ${color} p-6 h-64`}>
    <div className="absolute inset-0 bg-cover bg-center z-0" style={{ backgroundImage: `url(${bgImage})` }}></div>
    <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
    <div className="relative z-20 flex flex-col h-full">
      <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-white bg-opacity-20">
        <Icon className="w-8 h-8 text-white" />
      </div>
      <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
      <p className="text-white text-opacity-80 text-sm">{description}</p>
    </div>
  </div>
);

const Services = () => {
  const services = [
    {
      icon: Compass,
      title: 'Activities',
      description: "Embark on thrilling adventures across Tanzania's diverse landscapes, from wildlife safaris to mountain treks.",
      color: 'bg-blue-500',
      bgImage: '/assets/mountain-climb1.jpg'
    },
    {
      icon: Route,
      title: 'Travel Arrangements',
      description: "Let us handle the details of your journey with our comprehensive travel planning and logistics services.",
      color: 'bg-green-500',
      bgImage: '/assets/cat-trip4.jpg'
    },
    {
      icon: UserCheck,
      title: 'Private Guide',
      description: "Experience Tanzania with knowledgeable local guides who provide personalized insights and expertise.",
      color: 'bg-yellow-500',
      bgImage: '/assets/IMAGE-114.jpg'
    },
    {
      icon: Map,
      title: 'Location Manager',
      description: "Discover hidden gems and navigate Tanzania effortlessly with our expert location management services.",
      color: 'bg-red-500',
      bgImage: '/assets/beach2.jpg'
    },
  ];

  return (
    <section className="py-16 bg-white mt-36">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0 order-2 lg:order-1">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {services.map((service, index) => (
                <ServiceItem
                  key={index}
                  icon={service.icon}
                  title={service.title}
                  description={service.description}
                  bgImage={service.bgImage}
                  color={service.color}
                />
              ))}
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-4 order-1 lg:order-2 flex items-center justify-center">
            <div className="lg:pl-12">
              <span className="text-blue-600 text-sm font-semibold uppercase tracking-wider">Welcome to Lorta Tours</span>
              <h2 className="text-4xl font-bold mt-2 mb-6">It's time to start your adventure</h2>
              <p className="mb-6 text-gray-600">
                Discover the beauty of Tanzania with Lorta Tours. From majestic national parks to stunning beaches, we offer tailored experiences for every traveler.
              </p>
              <p className="mb-8 text-gray-600">
                Join us for unforgettable adventures in Tanzania. Whether it's exploring wildlife in national parks, conquering the heights of Kilimanjaro, or relaxing on serene beaches, we ensure a journey like no other.
              </p>
              <a href="/" className="inline-block bg-indigo-500 text-white font-semibold py-3 px-6 rounded-lg hover:bg-indigo-800 transition duration-300 mx-auto text-center md:inline-block">
                    Plan Your Dream Trip
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
