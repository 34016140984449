import React, { useEffect, useState } from 'react'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        }
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
                isScrolled ? 'bg-white text-black shadow-md' : 'bg-transparent text-white'
        }`}>
            <div className="container mx-auto px-4">
                <div className="flex justify-between items-center py-4">
                    <a href="/" className="text-xl font-bold">
                        Lorta<span className="text-blue-400"> Tours </span>
                    </a>
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="lg:hidden focus:outline-none"
                        aria-label="Toggle navigation"
                    >
                        <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                            {isOpen ? (
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                            ) : (
                                    <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                                )}
                        </svg>
                    </button>
                    <div className={`lg:flex ${isOpen ? 'block' : 'hidden'}`}>
                        <ul className="lg:flex lg:items-center font-semibold mr-16">
                            {[
                                { label: 'Home', href: '/', active: true },
                                { label: 'About', href: '/about' },
                                { label: 'Safaris', href: '/safari' },
                                { label: 'Accommodations', href: '/accommodations' },
                                { label: 'Activities', href: '/activities' },
                                { label: 'Contact', href: '/contact' },
                            ].map((item) => (
                                    <li key={item.label} className="lg:ml-6 mt-3 lg:mt-0">
                                        <a
                                            href={item.href}
                                            className={`block py-2 ${
                                            item.active ? 'text-blue-400' : 'hover:text-blue-400'
                                            }`}
                                        >
                                            {item.label}
                                        </a>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};


export default Navbar
