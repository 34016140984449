import React from 'react';
import { Facebook, Twitter, Instagram, Linkedin, MapPin, Phone, Mail } from 'lucide-react';

const ContactForm = () => {
    return (
        <div className="container mx-auto px-4">
            <div className="flex flex-wrap -mx-4 border border-indigo-100">
                <div className="w-full md:w-1/2 mb-8 md:mb-0">
                    <div className="bg-gray-50 p-8 shadow-md">
                        <h3 className="text-3xl font-bold mb-6 text-customBlue">Get in touch</h3>
                        <form>
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700 mb-2">Full Name</label>
                                <input type="text" id="name" name="name" className="w-full px-3 py-2 border border-gray-300 rounded-md" placeholder="Your Full Name" />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
                                <input type="email" id="email" name="email" className="w-full px-3 py-2 border border-gray-300 rounded-md" placeholder="Your Email Address" />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="message" className="block text-gray-700 mb-2">Message</label>
                                <textarea id="message" name="message" rows="4" className="w-full px-3 py-2 border border-gray-300 rounded-md" placeholder="Your Message Here..."></textarea>
                            </div>
                            <button type="submit" className="bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-800 transition-colors">SEND MESSAGE</button>
                        </form>
                    </div>
                </div>
                <div className="w-full md:w-1/2">
                    <div className="bg-gray-300 text-customBlue h-full p-8 shadow-md">
                        <h5 className="text-3xl font-bold mb-6">Contact Information</h5>
                        <div className="space-y-4">
                            <div className="flex items-center text-lg">
                                <MapPin className="w-6 h-6 mr-4 text-blue-500" />
                                <p> Sakina-Arusha <br /> Tanzania </p>
                            </div>
                            <div className="flex items-center">
                                <Phone className="w-6 h-6 mr-4 text-blue-500" />
                                <p>(+255) 123-456789<br />(+255) 123-856475</p>
                            </div>
                            <div className="flex items-center text-lg">
                                <Mail className="w-6 h-6 mr-4 text-blue-500" />
                                <p>support@lortasafari.com<br />info@lortasafari.com</p>
                            </div>
                        </div>
                        <h5 className="text-xl font-bold mt-8 mb-4">Follow us on</h5>
                        <div className="flex space-x-4">
                            <a href="/" className="text-blue-500 hover:text-blue-600">
                                <Facebook className="w-6 h-6" />
                            </a>
                            <a href="/" className="text-blue-500 hover:text-blue-600">
                                <Twitter className="w-6 h-6" />
                            </a>
                            <a href="/" className="text-blue-500 hover:text-blue-600">
                                <Instagram className="w-6 h-6" />
                            </a>
                            <a href="/" className="text-blue-500 hover:text-blue-600">
                                <Linkedin className="w-6 h-6" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-16 mb-16 p-4 h-[700px] w-full">
                <iframe
                    src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&t=m&z=10&output=embed&iwloc=near"
                    title="London Eye, London, United Kingdom"
                    className="w-full h-full border-0"
                    loading="lazy"
                ></iframe>
            </div>
        </div>
    );
};

export default ContactForm;
