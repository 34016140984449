import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FilterSection = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="mb-6">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex justify-between items-center w-full text-lg font-semibold mb-2 focus:outline-none"
      >
        <span>{title}</span>
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isExpanded && <div className="space-y-2">{children}</div>}
    </div>
  );
};

const CheckboxList = ({ items, name, showMore = false }) => {
  const [expanded, setExpanded] = useState(false);
  const visibleItems = showMore && !expanded ? items.slice(0, 4) : items;

  return (
    <ul className="space-y-2">
      {visibleItems.map((item, index) => (
        <li key={index} className="flex items-center justify-between">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name={name}
              value={item.value}
              className="form-checkbox"
            />
            <span>{item.label}</span>
          </label>
          <span className="text-sm text-gray-500">({item.count})</span>
        </li>
      ))}
      {showMore && items.length > 4 && (
        <li>
          <button
            onClick={() => setExpanded(!expanded)}
            className="text-blue-600 hover:underline"
          >
            {expanded ? 'Show less' : `Show all ${items.length}`}
          </button>
        </li>
      )}
    </ul>
  );
};

const RangeSlider = ({ min, max, title, suffix, valueFormat }) => {
  const [value, setValue] = useState(min);

  const formatValue = (val) => {
    if (valueFormat === 'price') {
      return `$${val.toLocaleString()}`;
    }
    return `${val} ${suffix}`;
  };

  return (
    <div>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="w-full"
      />
      <div className="flex justify-between mt-2">
        <span>{formatValue(min)}</span>
        <span>{formatValue(value)}</span>
        <span>{formatValue(max)}</span>
      </div>
    </div>
  );
};

const SidebarSidebar = () => {
  const destinations = [
    { label: 'Serengeti National Park', value: 'serengeti-national-park', count: 5 },
    { label: 'Ngorongoro National Park', value: 'ngorongoro-national-park', count: 2 },
    { label: 'Mikumi National Park', value: 'mikumi-national-park', count: 4 },
    { label: 'Manyara National Park', value: 'manyara-national-park', count: 2 },
    { label: 'Zanzibar Island', value: 'zanzibar', count: 1 },
    { label: 'Arusha National Park', value: 'arusha-national-park', count: 3 },
    { label: 'Tarangire National Park', value: 'tarangire-national-park', count: 1 },
    { label: 'Saadani National Park', value: 'saadani-national-park', count: 1 },
    { label: 'kilimanyaro', value: 'kilimanyaro', count: 3 },
  ];

  const activities = [
    { label: 'Boating', value: 'boating', count: 3 },
    { label: 'City Tour', value: 'city-tour', count: 9 },
    { label: 'Culture', value: 'culture', count: 1 },
    { label: 'Cycling', value: 'cycling', count: 6 },
    { label: 'Hiking', value: 'hiking', count: 6 },
    { label: 'Jungle Safari', value: 'jungle-safari', count: 8 },
    { label: 'Paragliding', value: 'paragliding', count: 1 },
  ];

  const tripTypes = [
    { label: 'Budget Travel', value: 'budget-travel', count: 6 },
    { label: 'Child-friendly', value: 'child-friendly', count: 8 },
    { label: 'Cultural', value: 'cultural', count: 9 },
    { label: 'Dog-friendly', value: 'dog-friendly', count: 7 },
    { label: 'High Altitude', value: 'high-altitude', count: 7 },
  ];

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 w-72">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold">Criteria</h2>
        <button className="text-blue-600 hover:underline">Clear all</button>
      </div>

      <FilterSection title="Destination">
        <CheckboxList items={destinations} name="destination" showMore={true} />
      </FilterSection>

      <FilterSection title="Price">
        <RangeSlider
          min={30}
          max={2300}
          suffix=""
          valueFormat="price"
        />
      </FilterSection>

      <FilterSection title="Duration">
        <RangeSlider
          min={0}
          max={11}
          suffix="Days"
          valueFormat="duration"
        />
      </FilterSection>

      <FilterSection title="Activities">
        <CheckboxList items={activities} name="activities" showMore={true} />
      </FilterSection>

      <FilterSection title="Trip Types">
        <CheckboxList items={tripTypes} name="trip_types" showMore={true} />
      </FilterSection>
    </div>
  );
};

export default SidebarSidebar;
