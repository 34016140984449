import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Clock, Users } from 'lucide-react';

const AccommodationsData = [
    {
        image: "/assets/NgorongoroLion’sPawCamp.jpg",
        featured: true,
        title: "Ngorongoro Lion’s Paw Camp",
        location: "Ngorongoro",
        description: "Nestled in a grove of acacia trees on the eastern rim of the Ngorongoro crater, the Lion’s Paw Camp offers 8 spacious ensuite tents with classic decor, lush beds, and luxury bathrooms. The crater bottom is just a 10-minute drive away.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/ngorongoro-lions-paw-camp"
    },
    {
        image: "/assets/BaobabMaraLuxuryCamp.jpg",
        featured: true,
        title: "Baobab Mara Luxury Camp",
        location: "Northern Serengeti",
        description: "An ideal starting point for safaris in the northern Serengeti. A migrating camp that finds the perfect spot for Great Migration adventures.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/baobab-mara-luxury-camp"
    },
    {
        image: "/assets/nimali-mara.jpg",
        featured: true,
        title: "Nimali Mara",
        location: "Northern Serengeti",
        description: "A hidden oasis providing ten spacious and beautifully designed tents with stunning views over the Serengeti plains.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/nimali-mara"
    },
    {
        image: "/assets/SerengetiMaraRiverCamp.jpg",
        featured: true,
        title: "Serengeti Mara River Camp",
        location: "Northern Serengeti",
        description: "Located just 300 meters from the Mara River, offering quick access to the wildebeest crossing during the Great Migration.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/serengeti-mara-river-camp"
    },
    {
        image: "/assets/lake-eyasi-safari-lodge.jpg",
        featured: true,
        title: "Lake Eyasi Safari Lodge",
        location: "Lake Eyasi",
        description: "Offers stunning views over Lake Eyasi and the Ngorongoro Conservation Area with 16 spacious rooms, each with a private verandah.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/lake-eyasi-safari-lodge"
    },
    {
        image: "/assets/TheKilimanjaroLuxuryCamp.jpg",
        featured: true,
        title: "The Kilimanjaro Luxury Camp",
        location: "Moshi",
        description: "Located near Mount Kilimanjaro, offering luxury tents and cottages for groups or families with beautiful views of Mount Kili.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/kilimanjaro-luxury-camp"
    },
    {
        image: "/assets/ChanyaLodge.jpg",
        featured: true,
        title: "Chanya Lodge",
        location: "Moshi",
        description: "Situated at the foothills of Mount Kilimanjaro, a 50-minute drive from Kilimanjaro International Airport, perfect for trekkers and safari seekers.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/chanya-lodge"
    },
    {
        image: "/assets/NgorongoroSerenaSafariLodge.jpg",
        featured: true,
        title: "Ngorongoro Serena Safari Lodge",
        location: "Ngorongoro",
        description: "Offers splendid views over the Ngorongoro crater, featuring 75 rustic rooms looped around the crater rim.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/ngorongoro-serena-safari-lodge"
    },
    {
        image: "/assets/lake-natron-halisi-camp.jpg",
        featured: true,
        title: "Lake Natron Halisi Camp",
        location: "Lake Natron",
        description: "A remote, private camp with 10 large, superior walk-in tents. A birder’s paradise on the southern shore of Lake Natron.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/lake-natron-halisi-camp"
    },
    {
        image: "/assets/TheMaridadiHotel.jpg",
        featured: true,
        title: "The Maridadi Hotel",
        location: "Moshi",
        description: "A boutique hotel offering 24 comfortable rooms, each with modern amenities and a private balcony, close to Moshi town center.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/maridadi-hotel"
    },
    {
        image: "/assets/BambooZanzibar.jpg",
        featured: true,
        title: "Bamboo Zanzibar",
        location: "Zanzibar - Jambiani",
        description: "A small design hotel in Jambiani, featuring 10 ocean-view suites in a minimalist, chic style.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/bamboo-zanzibar"
    },
    {
        image: "/assets/MnembaIslandLodge.jpg",
        featured: true,
        title: "Mnemba Island Lodge",
        location: "Zanzibar - Mnemba Island",
        description: "A private island lodge, offering romantic hideaways with stunning views over the Mnemba atoll.",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/mnemba-island-lodge"
    }
];

const AccommodationCard = () => {
    return (
        <div className="space-y-4">
            {AccommodationsData.map((destination, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                    <div className="flex flex-col sm:flex-row">
                        {/* Updated Image Container with Fixed Width and Height */}
                        <div className="relative w-full sm:w-64 h-64"> {/* Fixed width and height */}
                            <img
                                src={destination.image}
                                alt={destination.title}
                                className="w-72 h-full object-cover" // Ensuring full width and height with cover mode
                            />
                        </div>
                        <div className="flex-grow p-4">
                            <h2 className="text-xl font-semibold mb-2">{destination.title}</h2>
                            <p className="text-gray-600 text-sm mb-4">{destination.description}</p>
                            <div className="flex items-center text-gray-600 text-sm mb-2">
                                <MapPin size={16} className="mr-1" />
                                <span>{destination.location}</span>
                            </div>
                            <div className="flex justify-between items-center mt-4">
                                <div className="border-t pt-4 w-full">
                                    <Link
                                        to={`/safari/${index}`}
                                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
                                    >
                                        View Details
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AccommodationCard;
