import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from 'lucide-react';
import Navbar from '../component/Navbar';
import ActivitySidebar from '../component/ActivitiesSidebar';
import TripCard from '../component/TripCard';

const activities = [
    {
        title: "Activities",
        backgroundImage: "/assets/mountain-climb2.jpg"
    }
]

const ActivitiesPage = () => {
    return (
        <div>
            <Navbar />

            {activities.map((section, index) => (
                <section
                    className="relative h-screen bg-cover bg-center"
                    style={{ backgroundImage: `url(${section.backgroundImage})` }}
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="container mx-auto px-4">
                        <div className="flex h-screen items-end justify-center">
                            <div key={index} className="text-center pb-20 relative z-10">
                                <nav className="flex justify-center text-lg mb-4">
                                    <Link to="/" className="text-gray-300 hover:text-white transition duration-300 ease-in-out">
                                        Home
                                        <ChevronRightIcon className="inline-block w-4 h-4 ml-2 mr-2" />
                                    </Link>
                                    <span className="text-white">
                                        {section.title}
                                        <ChevronRightIcon className="inline-block w-4 h-4 ml-2" />
                                    </span>
                                </nav>
                                <h1 className="text-4xl md:text-5xl font-bold text-white">{section.title}</h1>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="flex flex-col lg:flex-row gap-8">
                    <div className="w-full lg:w-1/4">
                        <ActivitySidebar />
                    </div>
                    <div className="w-full lg:w-3/4">
                        <TripCard />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivitiesPage;
