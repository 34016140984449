import React, { useState } from 'react';

const ContactIntroCard = () => {
    return (
        <div>
            <section className="pt-16">
                <div className="container max-w-8xl ml-8">
                    <div className="flex justify-center">
                        <div className="w-full text-center">
                            <div
                                className="relative bg-cover bg-center py-16 px-4"
                                style={{ backgroundImage: "url(/assets/animal42.jpg)" }}
                            >
                                <div className="absolute inset-0 bg-black opacity-50"></div>
                                <div className="relative z-10">
                                    <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
                                        Feel Free To Reach Out To Us
                                    </h2>
                                    <p className="text-center text-white text-lg mb-6">
                                        Experience the best of Tanzania with us. <br />
                                        From breathtaking national parks and pristine beaches to exhilarating mountain climbing adventures.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default ContactIntroCard;
