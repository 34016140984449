import React, { useState } from 'react';
import TripSearchForm from './SearchForm';

const Search = () => {
    const [activeTab, setActiveTab] = useState('tour');

    return (
        <div className="bg-gray-100 pb-8 mb-32">
            <section className="pt-32 pb-8">
                <div className="container mx-auto px-4">
                    <div className="flex justify-center">
                        <div className="w-full text-center">
                            <div
                                className="relative bg-cover bg-center py-16 px-4"
                                style={{ backgroundImage: "url(/assets/animal42.jpg)" }}
                            >
                                <div className="absolute inset-0 bg-black opacity-50"></div>
                                <div className="relative z-10">
                                    <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
                                        Welcome To Lorta Tours
                                    </h2>
                                    <p className="text-center text-white text-lg mb-6">
                                        Experience the best of Tanzania with us. <br />
                                        From breathtaking national parks and pristine beaches to exhilarating mountain climbing adventures.
                                    </p>
                                    <a
                                        href="/"
                                        className="inline-block bg-indigo-500 text-white font-bold py-3 px-6 rounded-full hover:bg-indigo-800 transition duration-300"
                                    >
                                        Start Your Adventure
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TripSearchForm />

        </div>
    );
};

export default Search;
