import Itenaries from "../component/Itenaries";
import Hero from "../component/Hero";
import Navbar from "../component/Navbar";
import PlacesToVisit from "../component/PlacesToVisit";
import Services from "../component/Services";
import AboutUs from "../component/AboutUs";
import Testimonial from "../component/Testimonial";
import Search from "../component/Search";
import WhyChooseUs from "../component/WhyChooseUs";
import ImageGrid from "../component/ImageGrid";
import Footer from "../component/Footer";
import PopularDestinations from "../component/PopularDestinations";

function Home() {
  return (
    <div className="App">
        <Navbar />
        <Hero />
        <Services />
        <PlacesToVisit />
        <Itenaries />
        <Search />
        <PopularDestinations />
        <AboutUs />
        <Testimonial />
        <WhyChooseUs />
        <ImageGrid />
        <Footer />
    </div>
  );
}

export default Home;
