import React from 'react';
import { Target, Eye, Heart } from 'lucide-react';

const CultureItem = ({ icon, title, description }) => (
  <div className="mb-8 border border-gray-100 rounded-lg shadow-gray-100 p-2">
    <div className="flex items-center mb-2">
      {icon}
      <h3 className="text-xl font-semibold ml-2">{title}</h3>
    </div>
    <p className="text-gray-600">{description}</p>
  </div>
);

const AboutCultureSection = () => {
  return (
    <section className="py-16">
      <div className="container mx-auto h-96 px-4">
        <div className="flex flex-wrap -mx-4 h-full">
          {/* Left Column */}
          <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0 flex items-center pb-16">
            <div className="relative">
              <h4 className="text-blue-600 font-semibold mb-2">Our Culture</h4>
              <h2 className="text-3xl font-bold mb-4">Gathering moments.</h2>
              <p className="text-gray-600">
                As the travel market grows further in India so does issues of security and safety of foreigners travelling to India every year. Though government is doing its bit, there is no direct mechanism to help foreigners in distress.
              </p>
            </div>
          </div>

          {/* Right Column */}
          <div className="w-full h-full lg:w-1/2 px-4">
            <div className="flex flex-wrap -mx-4 h-full">
              <div className="w-full md:w-1/2 px-4 flex items-center">
                <CultureItem
                  icon={<Target className="w-6 h-6 text-blue-500" />}
                  title="Mission"
                  description="Providing best travel experience to anyone from any corner."
                />
              </div>
              <div className="w-full md:w-1/2 px-4 mt-16">
                <CultureItem
                  icon={<Eye className="w-6 h-6 text-blue-500" />}
                  title="Vision"
                  description="Providing best travel experience to anyone from any corner."
                />
                <CultureItem
                  icon={<Heart className="w-6 h-6 text-blue-500" />}
                  title="Value"
                  description="Providing best travel experience to anyone from any corner."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCultureSection;
