import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Droplet, Bed, Mountain, Umbrella} from 'lucide-react';

export const destinations = [
    {
        id: "3-days-ngorongoro-safari",
        image: "/assets/animal2.jpg",
        price: "$550",
        days: "3 Days Tour",
        title: "3-Day Ngorongoro Crater Safari: Scenic Game Drives and Stunning Wildlife",
        description: "Experience the breathtaking Ngorongoro Crater on this 3-day safari. Explore diverse landscapes and wildlife, including lions, rhinos, and countless bird species",
        location: "Arusha - Tanzania",
        amenities: [
            { icon: Droplet, count: 2 },
            { icon: Bed, count: 3 },
            { icon: Mountain, label: "Near Mountain" }
        ]
    },
    {
        id: "3-days-zanzibar-safari",
        image: "/assets/beach1.jpg",
        price: "$550",
        days: "7 Days Tour",
        title: "Unveiling Zanzibar's Enchantment: A Curated 7-Day Itinerary with Seamless Inclusions",
        description: "Embark on an unforgettable journey to Zanzibar, Tanzania, a captivating island paradise where pristine beaches, vibrant culture, and delectable cuisine seamlessly blend",
        location: "Zanzibar - Tanzania",
        amenities: [
            { icon: Droplet, count: 2 },
            { icon: Bed, count: 3 },
            { icon: Umbrella, label: "Near Beach" }
        ]
    },
    {
        id: "3-days-mikumi-safari",
        image: "/assets/animal3.jpg",
        price: "$550",
        days: "3 Days Tour",
        title: "Three-Day Mikumi Safari Adventure: Wildlife, Culture, and Maasai Encounters",
        description: "Three-day Mikumi National Park safari is the best tour through which you can experience people, culture, and wildlife on game drives, observing the fauna and flora in their natural habitat",
        location: "Morogoro - Tanzania",
        featured: true,
        amenities: [
            { icon: Droplet, count: 2 },
            { icon: Bed, count: 3 },
            { icon: Umbrella, label: "Near Beach" }
        ]
    },
    {
        id: "4-days-gombeandugalla-safari",
        image: "/assets/animal2.jpg",
        price: "$550",
        days: "4 Days Tour",
        title: "4-Day Safari: Chimpanzee Trekking in Gombe & Wildlife Wonders in Ugalla National Park",
        description: "Gombe has named by different travelers as best attraction for chimpanzee trekking and primate species, the place is known worldwide due to project conducted by Dr. jane Goodall",
        location: "Kigoma - Tanzania",
        amenities: [
            { icon: Droplet, count: 2 },
            { icon: Bed, count: 3 },
            { icon: Mountain, label: "Near Mountain" }
        ]
    },
    {
        id: "6-days-mikumi-safari",
        image: "/assets/animal1.jpg",
        price: "$550",
        days: "6 Days Tour",
        title: "Ultimate 6-Day Safari: Discover Mikumi National Park and Selous Game Reserve",
        description: "Embark on an unforgettable safari through Mikumi National Park and Selous Game Reserve. This tour offers a captivating blend of wildlife encounters and cultural experiences.",
        location: "Morogoro - Tanzania",
        amenities: [
            { icon: Droplet, count: 2 },
            { icon: Bed, count: 3 },
            { icon: Umbrella, label: "Near Beach" }
        ]
    },
    {
        id: "3-days-serengeti-ngorongoro-manyara-tarangire-safari",
        image: "/assets/animal3.jpg",
        price: "$550",
        days: "3 Days Tour",
        title: "3-Day Safari Adventure: Serengeti, Ngorongoro Crater, and Lake Manyara/Tarangire",
        description: "Begin with a thrilling game drive and photo opportunities in Serengeti National Park, followed by breathtaking crater views and game viewing in the Ngorongoro Conservation Area.",
        location: "Arusha - Tanzania",
        amenities: [
            { icon: Droplet, count: 2 },
            { icon: Bed, count: 3 },
            { icon: Umbrella, label: "Near Beach" }
        ]
    },
    // Add more destination objects for the remaining 4 destinations
];

const DestinationCard = ({ destination }) => (
    <div className="w-full md:w-1/3 p-4">
        <div className="bg-white rounded-lg overflow-hidden shadow-lg">
            <Link to={`/safari`} className="text-gray-800 hover:text-blue-600">
                <div className="relative">
                    <img src={destination.image} alt={destination.title} className="w-full h-64 object-cover" />
                    <span className="absolute top-0 right-0 bg-yellow-400 text-white px-2 py-1 m-2 rounded">
                        {destination.price}/person
                    </span>
                </div>
            </Link>
            <div className="p-4">
                <span className="text-sm text-gray-600">{destination.days}</span>
                <h3 className="text-xl font-semibold mt-2 hover:text-indigo-800">
                    <Link to={`/safari`} className="text-gray-800 hover:text-blue-600">
                        {destination.title}
                    </Link>
                </h3>
                <p className="flex items-center text-gray-600 mt-2">
                    {destination.description}
                </p>
                <p className="flex items-center text-indigo-600 mt-2">
                    <MapPin className="w-4 h-4 mr-1" /> {destination.location}
                </p>
                <ul className="flex justify-between mt-4">
                    {destination.amenities.map((amenity, index) => (
                        <li key={index} className="flex items-center">
                            <amenity.icon className="w-5 h-5 mr-1" />
                            <span>{amenity.count || amenity.label}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </div>
);

const Itenaries = () => {
    return (
        <section className="py-16 mt-16">
            <div className="container mx-auto px-4">
                <div className="text-center mb-12">
                    <span className="text-sm uppercase text-gray-500"> Featured Trips </span>
                    <h2 className="text-3xl font-bold mt-2"> Keep Calm and Travel On </h2>
                </div>
                <div className="flex flex-wrap -mx-4">
                    {destinations.map(destination => (
                        <DestinationCard key={destination.id} destination={destination} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Itenaries;
