import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from 'lucide-react';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import AboutDescriptionSection from '../component/AboutDescriptionSection';
import AboutFeaturesSection from '../component/AboutFeaturesSection';
import AboutCultureSection from '../component/AboutCultureSection';
import AboutTestimonialSection from '../component/AboutTestimonialSection';
import AboutTeamSection from '../component/AboutTeamSection';

const about = [
    {
        title: "About Us",
        backgroundImage: "/assets/animal27.jpg"
    }
]

const About = () => {
    return (
        <div>
            <Navbar />

            {about.map((section, index) => (
                <section
                    className="relative h-screen bg-cover bg-center"
                    style={{ backgroundImage: `url(${section.backgroundImage})` }}
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="container mx-auto px-4">
                        <div className="flex h-screen items-end justify-center">
                            <div key={index} className="text-center pb-20 relative z-10">
                                <nav className="flex justify-center text-lg mb-4">
                                    <Link to="/" className="text-gray-300 hover:text-white transition duration-300 ease-in-out">
                                        Home
                                        <ChevronRightIcon className="inline-block w-4 h-4 ml-2 mr-2" />
                                    </Link>
                                    <span className="text-white">
                                        {section.title}
                                        <ChevronRightIcon className="inline-block w-4 h-4 ml-2" />
                                    </span>
                                </nav>
                                <h1 className="text-4xl md:text-5xl font-bold text-white">{section.title}</h1>
                            </div>
                        </div>
                    </div>
                </section>
            ))}

            <AboutDescriptionSection />
            <AboutFeaturesSection />
            <AboutCultureSection />
            <AboutTestimonialSection />
            <AboutTeamSection />
            <Footer />
        </div>
    );
};

export default About;
