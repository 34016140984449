import React, { useState } from 'react';

const DestinationCard = ({ title, tripCount, subCategories = [], imageUrl, animalName }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className="bg-white rounded-lg shadow-md overflow-hidden h-96"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="relative aspect-square">
                <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
                {subCategories.length > 0 && (
                    <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-2">
                        <div className="flex flex-wrap gap-2">
                            {subCategories.map((subCat, index) => (
                                <a key={index} href={subCat.link} className="text-white text-sm hover:underline">
                                    {subCat.name}
                                </a>
                            ))}
                        </div>
                    </div>
                )}
                {isHovered && (
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
                        <span className="text-white text-2xl font-bold">{animalName}</span>
                        <span className="text-blue-500 text-2xl font-bold">{title}</span>
                    </div>
                )}
            </div>
            <div className="p-4">
                <h2 className="text-xl font-semibold">
                    <a href={`/destinations/${title.toLowerCase()}`} className="text-gray-800 hover:text-blue-600">
                        {title}
                    </a>
                    <span className="text-sm text-gray-500 ml-2">({tripCount} Trip{tripCount !== 1 ? 's' : ''})</span>
                </h2>
            </div>
        </div>
    );
};

const PopularDestinations = () => {
    const destinations = [
        {
            title: 'Arusha National Park',
            tripCount: 5,
            imageUrl: '/assets/animal3.jpg',
            animalName: 'Tiger'
        },
        {
            title: 'Tarangire National Park',
            tripCount: 3,
            imageUrl: '/assets/animal41.jpg',
            animalName: 'Dolphin'
        },
        {
            title: 'Serengeti National Park',
            tripCount: 1,
            imageUrl: '/assets/animal2.jpg',
            animalName: 'Lion'
        },
        {
            title: 'Manyara National Park',
            tripCount: 1,
            imageUrl: '/assets/animal5.jpg',
            animalName: 'Camel'
        },
        {
            title: 'Zanzibar',
            tripCount: 1,
            imageUrl: '/assets/animal1.jpg',
            animalName: 'Puffin'
        },
        {
            title: 'Mikumi',
            tripCount: 1,
            imageUrl: '/assets/animal2.jpg',
            animalName: 'Red-crowned Crane'
        },
    ];

    return (
        <div className="mt-4 mb-16 p-4">
            <div className="container mx-auto px-16">
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold mb-4 animate-fadeIn">Popular Destinations</h2>
                    <p className="text-gray-600 max-w-2xl mx-auto animate-fadeIn animation-delay-200">
                        Here are lots of interesting destinations to visit, but don't be confused - they're already grouped by category.
                    </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 animate-fadeIn animation-delay-300">
                    {destinations.map((destination, index) => (
                        <DestinationCard key={index} {...destination} />
                    ))}
                </div>
            </div>
            <div className="flex justify-center mt-8 mb-16">
                <a
                    href="/"
                    className="inline-block bg-indigo-500 text-white font-bold py-3 px-6 mt-4 rounded-full hover:bg-indigo-800 transition duration-300"
                >
                    Discover More Destinations
                </a>
            </div>
        </div>
    );
};

export default PopularDestinations;
