import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const destinations = [
    { name: 'Serengeti', tours: 8, image: '/assets/animal24.jpg' },
    { name: 'Ngorongoro', tours: 2, image: '/assets/animal8.jpg' },
    { name: 'Tarangire', tours: 5, image: '/assets/animal44.jpg' },
    { name: 'Saadani', tours: 5, image: '/assets/animal23.jpg' },
    { name: 'Arusha National Park', tours: 7, image: '/assets/animal51.jpg' },
    { name: 'Mikumi', tours: 7, image: '/assets/animal47.jpg' },
    { name: 'Manyara National Park', tours: 7, image: '/assets/animal41.jpg' },
    { name: 'Zanzibar', tours: 7, image: '/assets/beach3.jpg' },
];

const PlacesToVisit = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const scrollRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const scroll = (direction) => {
        const container = scrollRef.current;
        if (container) {
            const scrollAmount = 300; // Adjust this value to control scroll distance
            const newPosition = direction === 'left'
                ? Math.max(scrollPosition - scrollAmount, 0)
                : Math.min(scrollPosition + scrollAmount, container.scrollWidth - container.clientWidth);

            container.scrollTo({
                left: newPosition,
                behavior: 'smooth'
            });
            setScrollPosition(newPosition);
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === destinations.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000);

        return () => clearInterval(timer);
    }, []);

    return (
        <section className="py-16 bg-cover bg-center bg-gray-100 mt-36" style={{backgroundImage: "url('/images/bg_3.jpg')"}}>
            <div className="container mx-auto px-4">
                <div className="text-center mb-12">
                    <span className="text-blue-600 text-sm font-semibold uppercase tracking-wider">Places To Visit In Tanzania</span>
                    <h2 className="text-4xl font-bold mt-2"> Best Places To Visit On Your Vacation </h2>
                </div>
            </div>
            <div className="container mx-auto px-4 relative">
                <div className="flex items-center">
                    <button
                        onClick={() => scroll('left')}
                        className="absolute left-0 z-10 bg-white bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition duration-300"
                    >
                        <ChevronLeft className="w-6 h-6 text-gray-800" />
                    </button>
                    <div
                        ref={scrollRef}
                        className="flex overflow-x-auto scrollbar-hide space-x-4 py-4 transition-transform duration-300 ease-in-out"
                        style={{ scrollBehavior: 'smooth', transform: `translateX(-${currentIndex * (15 / destinations.length)}%)` }}
                    >
                        {destinations.map((destination, index) => (
                            <div key={index} className="flex-none w-64">
                                <div className="relative h-64 rounded-lg overflow-hidden group">
                                    <img
                                        src={destination.image}
                                        alt={destination.name}
                                        className="w-full h-full object-cover"
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center text-white transition duration-300 opacity-100 group-hover:opacity-75">
                                        <h3 className="text-2xl font-semibold">{destination.name}</h3>
                                        <span>{destination.tours} Tours</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={() => scroll('right')}
                        className="absolute right-0 z-10 bg-white bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition duration-300"
                    >
                        <ChevronRight className="w-6 h-6 text-gray-800" />
                    </button>
                </div>
            </div>
        </section>
    );
};

export default PlacesToVisit;
