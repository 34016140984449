import React from 'react';

const AboutDescriptionSection = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
    {
      // <section className="relative bg-gray-800 text-white py-20">
      //   <div className="absolute inset-0 bg-black opacity-50"></div>
      //   <div className="container mx-auto px-4 relative z-10">
      //     <h2 className="text-4xl font-bold mb-4">About Us</h2>
      //   </div>
      // </section>
    }
      {/* Main Content */}
      <section className="py-16">
        <div className="container items-center mx-auto px-4 flex flex-wrap">
          {/* Left Column */}
          <div className="w-full lg:w-1/2 pr-0 lg:pr-8 mb-8 lg:mb-0">
            <div className="mb-16">
              <h3 className="text-2xl font-semibold mb-2">About Lorta Tours</h3>
              <h2 className="text-4xl font-bold mb-4">Unforgettable Journey Awaits</h2>
            </div>
            <p className="mb-6">
                Embark on a transformative adventure with Travel Nomad.
                Discover handpicked destinations and tailor-made travel packages designed to make your trip truly unforgettable.
            </p>
            <p>
               As travel evolves, so do the challenges of ensuring the safety and security of visitors.
               Recognizing this crucial need, our founder, Lorta from Tanzania, has dedicated herself to bridging the gap in the current tourism landscape.
               With a deep commitment to enhancing travel experiences, she aims to provide unparalleled support and peace of mind to every traveler exploring new horizons.
            </p>
          </div>

          {/* Right Column */}
          <div className="w-full lg:w-1/2">
            <img
              src="/assets/IMAGE-141.jpg"
              alt="Travel illustration"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutDescriptionSection;
