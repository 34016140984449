import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from 'lucide-react';
import Navbar from '../component/Navbar';
import SafariSidebar from '../component/SafariSidebar';
import TripCard from '../component/TripCard';
import { fetchItineraries } from '../component/ItineraryService'; // We'll create this service

const destinations = [
    {
        title: "Safaris",
        backgroundImage: "/assets/animal37.jpg"
    }
];

const SafariPage = () => {
    const [itineraries, setItineraries] = useState([]);

    useEffect(() => {
        const loadItineraries = async () => {
            const data = await fetchItineraries();
            setItineraries(data);
        };
        loadItineraries();
    }, []);


    return (
        <div>
            <Navbar />
            {destinations.map((section, index) => (
                <section
                    key={index}
                    className="relative h-screen bg-cover bg-center"
                    style={{ backgroundImage: `url(${section.backgroundImage})` }}
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="container mx-auto px-4">
                        <div className="flex h-screen items-end justify-center">
                            <div className="text-center pb-20 relative z-10">
                                <nav className="flex justify-center text-lg mb-4">
                                    <Link to="/" className="text-gray-300 hover:text-white transition duration-300 ease-in-out">
                                        Home
                                        <ChevronRightIcon className="inline-block w-4 h-4 ml-2 mr-2" />
                                    </Link>
                                    <span className="text-white">
                                        {section.title}
                                        <ChevronRightIcon className="inline-block w-4 h-4 ml-2" />
                                    </span>
                                </nav>
                                <h1 className="text-4xl md:text-5xl font-bold text-white">{section.title}</h1>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="flex flex-col lg:flex-row gap-4">
                    <div className="w-full lg:w-1/4">
                        <SafariSidebar />
                    </div>
                    <div className="w-full lg:w-3/4">
                        <TripCard trips={itineraries}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SafariPage;
