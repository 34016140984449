import React from 'react';

const ImageGrid = () => {
  // Sample image data
  const images = [
    { src: '/assets/animal4.jpg', alt: 'Image 4' },
    { src: '/assets/IMAGE-116.jpg', alt: 'Image 3' },
    { src: '/assets/maasai1.jpg', alt: 'Image 1' },
    { src: '/assets/cat-trip4.jpg', alt: 'Image 2' },
  ];

  return (
    <div className="container mx-auto px-4 mt-16">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-2 gap-0">
          {images.map((image, index) => (
            <div key={index} className="aspect-w-16 aspect-h-9 overflow-hidden">
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageGrid;
