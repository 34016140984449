import React, { useState } from 'react';
import { Star } from 'lucide-react';

const testimonials = [
  {
    id: 1,
    text: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    name: "Roger Scott",
    position: "Marketing Manager",
    image: "animal4.jpg"
  },
  {
    id: 2,
    text: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    name: "Jane Doe",
    position: "Product Manager",
    image: "/animal4.jpg"
  },
  {
    id: 3,
    text: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    name: "John Smith",
    position: "CEO",
    image: "/animal4.jpg"
  },
  // Add more testimonials as needed
];

const Testimonial = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section className="mt-16 py-16 bg-cover bg-center relative" style={{backgroundImage: "url(/assets/car-trip2.jpg)"}}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-12">
          <span className="text-primary text-sm uppercase font-semibold">Testimonial</span>
          <h2 className="text-3xl font-bold mt-2 text-white">Tourist Feedback</h2>
        </div>
        <div className="max-w-4xl mx-auto">
          <div className="bg-white rounded-lg shadow-lg p-8">
            <div className="flex justify-center mb-4">
              {[...Array(5)].map((_, i) => (
                <Star key={i} className="w-6 h-6 text-yellow-400 fill-current" />
              ))}
            </div>
            <p className="text-gray-600 mb-6 text-center">
              {testimonials[currentTestimonial].text}
            </p>
            <div className="flex items-center justify-center">
              <div className="w-16 h-16 rounded-full bg-cover bg-center mr-4"
                   style={{backgroundImage: `url(${testimonials[currentTestimonial].image})`}}></div>
              <div>
                <p className="font-semibold">{testimonials[currentTestimonial].name}</p>
                <span className="text-gray-500">{testimonials[currentTestimonial].position}</span>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-6">
            <button onClick={prevTestimonial} className="mx-2 px-4 py-2 bg-blue-700 rounded-lg text-white hover:bg-primary-dark">Previous</button>
            <button onClick={nextTestimonial} className="mx-2 px-4 py-2 bg-blue-700 rounded-lg text-white hover:bg-primary-dark">Next</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
