import React, { useState } from 'react';
import { MapPin, Activity, Clock, DollarSign } from 'lucide-react';

const destinations = [
  "Serengeti National Park",
  "Mikumi National Park",
  "Ngorongoro National Park",
  "Zanzibar",
  "Tarangire National Park",
  "Manyara National Park",
  "Kilimanjaro Mountain",
  "Arusha National Park",
  "Saadani National Park"
];

const activities = [
  "Hiking",
  "Culture",
  "Cycling",
  "Jungle Safari",
  "Balloon",
  "City Tour",
  "Boating"
];

const priceRanges = [
  { label: "$1500 - $3000 (Silver)", value: "1500-3000" },
  { label: "$3000 - $5000 (Gold/Platinum)", value: "3000-5000" },
  { label: "$5000 - $7500 (Platinum/Diamond Luxury)", value: "5000-7500" },
  { label: "$7500+ (Diamond Luxury)", value: "7500+" }
];

const durations = [
  "5 to 10 days",
  "10 to 15 days",
  "2 to 3 weeks",
  "Other"
];

const TripSearchForm = () => {
  const [destination, setDestination] = useState('');
  const [activity, setActivity] = useState('');
  const [duration, setDuration] = useState('');
  const [price, setPrice] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ destination, activity, duration, price });
    // Handle form submission
  };

  const SelectField = ({ icon: Icon, options, value, onChange, placeholder }) => (
    <div className="flex-1 min-w-[200px]">
      <div className="relative">
        <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-500" size={20} />
        <select
          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none bg-white"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="">{placeholder}</option>
          {options.map((option, index) => (
            <option key={index} value={typeof option === 'object' ? option.value : option}>
              {typeof option === 'object' ? option.label : option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-7xl mx-auto flex items-center justify-center">
      <form onSubmit={handleSubmit} className="flex flex-wrap gap-4">
        <SelectField
          icon={MapPin}
          options={destinations}
          value={destination}
          onChange={setDestination}
          placeholder="Select Destination"
        />

        <SelectField
          icon={Activity}
          options={activities}
          value={activity}
          onChange={setActivity}
          placeholder="Select Activity"
        />

        <SelectField
          icon={Clock}
          options={durations}
          value={duration}
          onChange={setDuration}
          placeholder="Select Duration"
        />

        <SelectField
          icon={DollarSign}
          options={priceRanges}
          value={price}
          onChange={setPrice}
          placeholder="Select Price Range"
        />

        <div className="w-full sm:w-auto">
          <button
            type="submit"
            className="w-full sm:w-auto bg-indigo-500 text-white px-6 py-2 rounded-md hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            SEARCH
          </button>
        </div>
      </form>
    </div>
  );
};

export default TripSearchForm;
