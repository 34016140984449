import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/HomePage";
import AboutPage  from "./pages/AboutPage";
import AccommodationsPage from "./pages/AccommodationsPage";
import SafariPage from "./pages/SafariPage";
import ItineraryDetails from "../src/component/ItineraryDetails";
import ActivitiesPage from "./pages/ActivitiesPage";
import ContactPage  from "./pages/ContactPage";

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/accommodations" element={<AccommodationsPage />} />
          <Route path="/safari" element={<SafariPage />} />
          <Route path="/safari/:id" element={<ItineraryDetails />} />
          <Route path="/activities" element={<ActivitiesPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
