import React from 'react';
import { MapPin, DollarSign, HeadphonesIcon } from 'lucide-react';

const FeatureBox = ({ icon, title, description }) => (
  <div className="flex flex-col items-center text-center p-6">
    <div className="bg-blue-500 rounded-full p-4 mb-4">
      {icon}
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const AboutFeaturesSection = () => {
  const features = [
    {
      icon: <MapPin size={24} color="white" />,
      title: "Handpicked Destination",
      description: "Our strict screening process means you're only seeing the best quality treks."
    },
    {
      icon: <DollarSign size={24} color="white" />,
      title: "Best Price Guaranteed",
      description: "Our Best Price Guarantee means that you can be sure of booking at the best rate."
    },
    {
      icon: <HeadphonesIcon size={24} color="white" />,
      title: "24/7 Customer Service",
      description: "Our customer team is standing by 24/7 to make your experience incredible."
    }
  ];

  return (
    <section className="bg-orange-50 py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureBox
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutFeaturesSection;
