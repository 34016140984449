import React, { useState, useEffect, useRef } from 'react';
import { Play, Pause } from 'lucide-react';

const useIntersectionObserver = (options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [options]);

  return [ref, isIntersecting];
};

const VideoSection = ({ videoSrc, onVideoEnd }) => {
  const videoRef = useRef(null);
  const [containerRef, isIntersecting] = useIntersectionObserver({ threshold: 0.5 });
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (isIntersecting && videoElement) {
      videoElement.play().catch(error => console.error("Error playing video:", error));
      setIsPlaying(true);
    } else if (!isIntersecting && videoElement) {
      videoElement.pause();
      setIsPlaying(false);
    }
  }, [isIntersecting]);

  const handlePlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play().catch(error => console.error("Error playing video:", error));
        setIsPlaying(true);
      } else {
        videoElement.pause();
        setIsPlaying(false);
      }
    }
  };

  return (
    <section ref={containerRef} className="relative h-screen bg-cover bg-center">
      <video
        ref={videoRef}
        className="absolute inset-0 w-full h-full object-cover"
        loop={false}
        muted
        playsInline
        onEnded={onVideoEnd}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="container mx-auto h-full flex items-center justify-center">
        <button
          onClick={handlePlayPause}
          className="relative z-10 w-24 h-24 rounded-full bg-white bg-opacity-25 flex items-center justify-center transition-all duration-300 hover:bg-opacity-50"
        >
          {isPlaying ? (
            <Pause className="w-12 h-12 text-white" />
          ) : (
            <Play className="w-12 h-12 text-white" />
          )}
        </button>
      </div>
    </section>
  );
};

const AboutUs = () => {
  const videos = [
    '/assets/lion2.mp4',
    '/assets/lion1.mp4',
    '/assets/cheetah.mp4',
    '/assets/elephant1.mp4',
    '/assets/zebras.mp4',
    '/assets/girrage.mp4',
  ];

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  return (
    <div className="relative">
      {/* Video Section */}
      <VideoSection
        key={currentVideoIndex}
        videoSrc={videos[currentVideoIndex]}
        onVideoEnd={handleVideoEnd}
      />

      {/* About Section */}
      <section className="relative bg-white -mt-24 md:-mt-48">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap">
            {/* Image Column */}
            <div className="w-44 md:w-1/2 relative mt-8">
              <div className="h-full md:absolute md:top-0 md:left-0 md:right-0 md:-mt-24 border-8 border-white">
                <div
                  className="h-64 md:h-full bg-cover bg-center"
                  style={{backgroundImage: "url(/assets/IMAGE-40.jpg)"}}
                ></div>
              </div>
            </div>
            {/* Text Column */}
            <div className="w-full md:w-1/2 p-8 md:p-16 md:pl-24">
              <span className="text-blue-500 uppercase tracking-wider">About Us</span>
              <h2 className="text-4xl font-bold mt-4 mb-6">Make Your Tour Memorable and Safe With Us</h2>
              <p className="mb-6">
                Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.
                Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.
              </p>
              <a href="/" className="inline-block bg-indigo-500 text-white py-3 px-6 rounded-full hover:bg-indigo-800 transition-colors duration-300">Book Your Destination</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
