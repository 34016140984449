import React from 'react';

const AboutTeamSection = () => {
    return (
        <div className="relative py-16">
            {/* Main content */}
            <div className="container mx-auto px-4">
                <div className="flex flex-col lg:flex-row items-center">
                    {/* Image */}
                    <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
                        <img
                            src="/assets/IMAGE-108.jpg"
                            alt="Our Team"
                            className="w-full h-auto rounded-lg shadow-lg"
                        />
                    </div>

                    {/* Text content */}
                    <div className="w-full lg:w-1/2 lg:pl-12 p-8 rounded-lg">
                        <h2 className="text-3xl font-bold mb-4">
                            Discover Lorta Tours: Your Gateway to Exceptional Experiences
                        </h2>
                        <p className="text-gray-600 mb-6">
                            At Lorta Tours, we believe in creating more than just travel itineraries;
                            we craft unforgettable journeys tailored to your unique desires.
                            Our dedicated team of travel experts works tirelessly to design and deliver immersive, enriching experiences that exceed your expectations.
                        </p>
                        <p className="text-gray-600">
                            We are committed to enhancing every aspect of your journey—from personalized travel planning
                            and seamless booking processes to providing on-the-ground support and ensuring the highest level of customer satisfaction.
                            With a passion for excellence and a keen eye for detail, our team continually strives to innovate and improve, ensuring that every trip with Lorta Tours is nothing short of extraordinary.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutTeamSection;
