import React from 'react';
import { Twitter, Facebook, Instagram, MapPin, Phone, Mail, Heart } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-customBlue mt-36 bg-opacity-90 bg-bottom bg-no-repeat pt-16" style={{backgroundImage: "url(images/bg_3.jpg)"}}>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          <div>
            <h2 className="text-xl font-semibold text-white mb-4">About</h2>
            <p className="text-gray-400 mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
            <div className="flex space-x-4">
              <a href="/" className="text-white hover:text-blue-400"><Twitter size={20} /></a>
              <a href="/" className="text-white hover:text-blue-600"><Facebook size={20} /></a>
              <a href="/" className="text-white hover:text-pink-500"><Instagram size={20} /></a>
            </div>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-white mb-4">Information</h2>
            <ul className="text-gray-400">
              {["Online Enquiry", "General Enquiries", "Booking Conditions", "Privacy and Policy", "Refund Policy", "Call Us"].map((item, index) => (
                <li key={index}><a href="/" className="hover:text-white py-2 block">{item}</a></li>
              ))}
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-white mb-4">Experience</h2>
            <ul className="text-gray-400">
              {["Adventure", "Hotel and Restaurant", "Beach", "Nature", "Camping", "Party"].map((item, index) => (
                <li key={index}><a href="/" className="hover:text-white py-2 block">{item}</a></li>
              ))}
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-white mb-4">Have a Questions?</h2>
            <ul className="text-gray-400">
              <li className="flex items-start mb-4">
                <MapPin className="mr-2 mt-1 flex-shrink-0" size={20} />
                <span> Sakina-Arusha, Tanzania</span>
              </li>
              <li className="flex items-center mb-4">
                <Phone className="mr-2 flex-shrink-0" size={20} />
                <a href="tel:+2 392 3929 210" className="hover:text-white">+255 73384929</a>
              </li>
              <li className="flex items-center mb-4">
                <Mail className="mr-2 flex-shrink-0" size={20} />
                <a href="mailto:info@lortatours.com" className="hover:text-white">info@lortatours.com</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-800 pt-8 pb-8">
          <p className="text-center text-gray-400">
            Copyright &copy;{new Date().getFullYear()} All rights reserved | This website is made with <Heart className="inline-block text-red-500" size={16} /> by <a href="https://3ts.co.tz/" target="_blank" rel="noopener noreferrer" className="text-red-500 hover:text-blue-400">3TS (Tulia Tanzania Technology Solutions)</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
