import { destinations } from '../component/Itenaries'; // Adjust the import path as needed

export const fetchItineraries = async () => {
    // Simulating an API call with a delay
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(destinations);
        }, 500); // 500ms delay to simulate network request
    });
};

export const fetchItineraryById = async (id) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const itinerary = destinations.find(dest => dest.id === parseInt(id));
            if (itinerary) {
                resolve(itinerary);
            } else {
                reject(new Error('Itinerary not found'));
            }
        }, 300);
    });
};
