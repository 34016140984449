export const zanzibarsGemsItinerary = {
    id: "3-days-zanzibar-safari",
    title: "Unveiling Zanzibar's Gems: A Curated 3-Day Itinerary",
    days: "3 Days",
    location: "Zanzibar, Tanzania",
    description: "Immerse yourself in the captivating allure of Zanzibar with this meticulously crafted itinerary, designed to showcase the island's cultural heart, captivating wildlife encounters, and pristine natural beauty. This program ensures a seamless and enriching experience, maximizing your exploration within a comfortable 3-day timeframe.",
    itineraryDetails: [
        {
            title: "Day 1: Unveiling Stone Town's Rich Tapestry and Prison Island's Gentle Giants",
            description: `Morning: Commence your Zanzibar odyssey with a captivating exploration of Stone Town, a UNESCO World Heritage Site. Embark on a professionally guided walking tour, delving into the labyrinthine alleyways, architectural marvels, and vibrant bazaars. Witness historical landmarks like the House of Wonders, the Old Fort, and the Palace Museum, gaining insights into the island's fascinating past.

Afternoon: Set sail for Prison Island, a captivating islet steeped in history. Explore the ruins of the old prison, now a haven for a colony of giant Aldabra tortoises. Interact with these gentle giants and learn about their remarkable longevity from your knowledgeable guide.

Sunset Serenade: As the sun dips below the horizon, unwind on the pristine sands of Nakupenda Sandbank, a breathtaking offshore islet. Savor a delectable seafood lunch, bask in the tranquility of the turquoise waters, and create unforgettable memories amidst this idyllic setting.`
        },
        {
            title: "Day 2: Embracing Zanzibar's Marine Majesty",
            description: `Morning Quest: Embark on an exhilarating dolphin tour, venturing into the turquoise waters surrounding Zanzibar. Keep your eyes peeled for playful pods of bottlenose dolphins, humpback dolphins, and spinner dolphins gracefully gliding through the waves. Witness these magnificent creatures in their natural habitat, creating lasting memories.

Cultural Delights: Return to Stone Town, where the city transforms under the cloak of night. Explore the bustling night markets, teeming with local crafts and artisanal treasures. In the evening, tantalize your taste buds with a traditional Swahili dinner, savoring the island's exquisite culinary heritage. Immerse yourself in the vibrant sounds of Taarab music and traditional dance performances, experiencing the heart of Zanzibar's cultural soul.`
        },
        {
            title: "Day 3: Unveiling Zanzibar's Pristine Coastline with a Safari Blue Tour",
            description: `Island Hopping Adventure: Embark on a captivating full-day Safari Blue tour, designed to showcase Zanzibar's breathtaking coastline. Sail aboard a traditional dhow, visiting secluded beaches fringed with swaying palm trees and crystal-clear turquoise waters. Unwind on pristine sandbanks, snorkel amidst vibrant coral reefs teeming with marine life, and create lasting memories in this idyllic island paradise.

Unwinding Farewell: As the day draws to a close, return to Stone Town or your designated departure point, carrying cherished memories of your Zanzibar adventure.`
        }
    ],
    inclusions: [
        "Seamless Transportation: Enjoy comfortable and reliable transportation throughout your itinerary, including airport transfers, ensuring a stress-free experience.",
        "Charming Accommodation: Relax in comfortable and well-appointed accommodation for two nights in the heart of Stone Town, allowing for convenient exploration.",
        "Enriching Guided Tours: Delve deeper into Zanzibar's history and culture with professionally guided tours of Stone Town and Prison Island, led by knowledgeable guides.",
        "Unforgettable Experiences: Embark on thrilling dolphin tours and a captivating Safari Blue tour, creating lasting memories amidst Zanzibar's captivating marine environment and pristine coastline.",
        "Selective Meals: Savor delicious meals as specified in your chosen itinerary, typically including breakfast and lunch."
    ],
    pricing: [
        { type: "With accommodation", price: "1200 USD per two people" },
        { type: "Without accommodation (only lunch on specific days)", price: "850 USD per two people" }
    ],
    faqs: [
        {
            question: "What is the best time to visit Zanzibar?",
            answer: "The best time to visit Zanzibar is during the dry seasons, from June to October and from December to March. These periods offer ideal weather for beach activities and sightseeing."
        },
        {
            question: "Is it safe to drink tap water in Zanzibar?",
            answer: "It's not recommended to drink tap water in Zanzibar. Stick to bottled water, which is readily available throughout the island."
        }
    ],
    mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d507247.8670823951!2d39.14260194656641!3d-6.213326356350163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185cd0f6f1b051c1%3A0xb9c54dd12e46fb42!2sZanzibar!5e0!3m2!1sen!2sus!4v1652893675243!5m2!1sen!2sus"
};


export const ngorongoroSafariTour = {
    id: "3-days-ngorongoro-safari",
    title: "3 Days Ngorongoro Safari Tour",
    days: "3 Days",
    location: "Ngorongoro Conservation Area, Tanzania",
    description: "Experience the wonders of Ngorongoro Conservation Area with this 3-day safari tour. Explore the world's largest intact volcanic caldera and witness an incredible diversity of wildlife in their natural habitat.",
    itineraryDetails: [
        {
            title: "Day 1: Arusha to Ngorongoro",
            description: `After breakfast, you will drive to Ngorongoro Crater. Upon arrival, you'll have lunch and then go for an afternoon game drive in the crater. Ngorongoro National Park, a World Heritage site, is home to over 500 bird species and 25,000 large animals. You'll explore diverse landscapes including montane forest, grassland, bushland, and various water sources. After the game drive, return to the lodge for dinner and overnight stay.`
        },
        {
            title: "Day 2: Full Day Game Viewing",
            description: `Enjoy a full day of game viewing after breakfast. You'll have the opportunity to spot a wide variety of animals including cheetahs, lions, black rhinoceros, hippopotamus, wildebeest, zebras, elephants, and many more. Bird enthusiasts will delight in the diverse avian species present, from ostriches to flamingoes. In the evening, return to the lodge for dinner and overnight stay.`
        },
        {
            title: "Day 3: Ngorongoro to Arusha",
            description: `After breakfast, you'll have the option of a community walk to meet the Maasai people and enjoy their traditional performances, or take a forest walk. Following lunch, you'll drive back to Arusha, marking the end of your safari adventure.`
        }
    ],
    inclusions: [
        "Pick up from hotel address in Arusha city",
        "Ngorongoro conservation fees",
        "Park entry fees for the car and customers",
        "Guided game viewing in the park",
        "Packed lunch box and water in car",
        "Professional Guide/driver",
        "Ground transportation in a 4X4 safari car",
        "Accommodation for 2 nights",
        "Meals at the lodges (Breakfast and Dinner)"
    ],
    exclusions: [
        "Airticket and visa fees",
        "Soft sodas and alcoholic drinks",
        "Other personal items/expenses"
    ],
    pricing: [
        { type: "Contact for pricing", price: "Based on number of persons and hotel options (Top-luxury / Luxury / Mid-range / Budget camps)" }
    ],
    faqs: [
        {
            question: "What is the best time to visit Ngorongoro?",
            answer: "The best time to visit Ngorongoro is during the dry season from June to October. However, the crater offers great wildlife viewing year-round."
        },
        {
            question: "What should I pack for the safari?",
            answer: "Pack light, comfortable clothing in neutral colors, a sun hat, sunglasses, sunscreen, insect repellent, and a good camera. Binoculars are also recommended for wildlife viewing."
        }
    ],
    mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255281.19321953108!2d35.41463448220903!3d-3.2477563705474993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18379c29fe788ecd%3A0x71f90310c2d3ab0b!2sNgorongoro%20Conservation%20Area!5e0!3m2!1sen!2sus!4v1652894523584!5m2!1sen!2sus"
};


export const mikumiNationalParkSafari = {
    title: "3-days-mikumi-safari",
    days: "3 days 2 nights",
    location: "Mikumi National Park",
    description: `
        This three-day Mikumi National Park safari offers the best experience to explore people, culture, and wildlife on game drives while observing the fauna and flora in their natural habitat. This tour also provides a unique opportunity to engage with the Maasai people in a Mikumi village. Don't miss this one-of-a-kind chance to immerse yourself in the unique landscapes of Africa.
    `,
    itineraryDetails: [
        {
            day: "Day 1",
            description: "Drive from Dar-es-Salaam to Mikumi National Park (approx. 6 hours) with a picnic lunch. On arrival, visit a Maasai Boma in the village until sunset. The Maasai guides will explain the past and present lifestyle of the Maasai in Tanzania. Dinner and overnight at Camp Bastian (Meal Plan: Full Board)."
        },
        {
            day: "Day 2",
            description: "After breakfast, depart for a full-day game drive in Mikumi National Park. Experience sightings of elephants, zebras, wildebeests, giraffes, impalas, warthogs, hippos, crocodiles, lions, and various bird species."
        },
        {
            day: "Day 3",
            description: "Enjoy a half-day game drive after breakfast. Stop for a hot lunch in the afternoon. Continue the drive en-route back to Dar es Salaam, where you will be dropped off at your hotel or residence."
        },
    ],
    inclusions: [
        "Park fees",
        "Accommodation",
        "Food and soft drinks",
        "Conservation fees",
        "Guide fee",
        "Safari car",
        "Transfers"
    ],
    exclusions: [
        "Personal purchases",
        "Flight tickets",
        "Extra services"
    ],
    pricing: [
        {
            type: "Price per person",
            price: "To be determined"
        }
    ],
    faqs: [],
    mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d130209.3714184441!2d37.5000!3d-7.4000!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18415f7c4bd01b35%3A0x20226f3e!2sMikumi%20National%20Park!5e0!3m2!1sen!2stz!4v1661781328459"
};


export const gombeAndUgallaSafari = {
    id: "4-days-gombeandugalla-safari",
    title: "4 Days in Gombe National Park and Ugalla National Park",
    days: "4 days 3 nights",
    location: "Gombe National Park and Ugalla National Park",
    description: `
        Tanzania has wonderfully diverse attractions with different views and attributes in the tourism sector. Gombe has been named by different travelers as the best attraction for chimpanzee trekking and primate species, known worldwide due to the project conducted by Dr. Jane Goodall. Ugalla River National Park, known for its elbow shape, is a source of Lake Tanganyika and the River Nile and is home to different species of animals, including the largest crocodile in Africa and the world.
    `,
    itineraryDetails: [
        {
            day: "Day 1",
            description: "Early morning pickup from your hotel in Dar es Salaam and transfer to the airport for a scheduled flight to Kigoma. Transfer to Kabirizi port for a boat to Gombe. After arrival, check in to the rest house, have a hot lunch, and start Chimpanzee tracking in the evening. Dinner and overnight at Tanapa Rest House/Tented Camp."
        },
        {
            day: "Day 2",
            description: "Morning chimpanzee tracking in the forest, visit Kakombe Waterfalls, and optionally climb Jane's Peak for a view of the park. Transfer to Kigoma town and continue the safari to Ugalla National Park."
        },
        {
            day: "Day 3",
            description: "Full day game drive in Ugalla National Park to see wildlife like lions, leopards, crocodiles, and various birds. Dinner and overnight at Tabora Hotel."
        },
        {
            day: "Day 4",
            description: "Return to the airport for the flight back home or to Dar es Salaam/Zanzibar."
        },
    ],
    inclusions: [
        "Guide fee",
        "Conservation fee",
        "Trekking fee",
        "Safari car",
        "Food and soft drinks",
        "Accommodations"
    ],
    exclusions: [
        "Flight ticket",
        "Personal purchase",
        "Tips"
    ],
    pricing: [
        {
            type: "Price per person",
            price: "USD 1500"
        }
    ],
    faqs: [],
    mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.316207841955!2d30.7199!3d-4.6712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19e1bb8aab7d4cdd%3A0x6c7d46e7bd780dd2!2sGombe%20National%20Park!5e0!3m2!1sen!2stz!4v1661780950125"
};


export const mikumiAndSelousSafari = {
    id: "6-days-mikumi-safari",
    title: "The Best 6 Days 5 Night at Mikumi National Park and Selous Game Reserve",
    days: "6 days 5 nights",
    location: "Mikumi National Park and Selous Game Reserve",
    description: `
        This six-day Mikumi and Selous (Nyerere) National Park safari is the best tour through which you can experience people, culture, and wildlife on game drives, observing the fauna and flora in their natural habitat. This tour will give you the amazing opportunity to get closer to the Maasai people in a village. So, don’t wait before taking this one-of-a-kind chance to immerse yourself in the unique landscapes of Africa.
    `,
    itineraryDetails: [
        {
            day: "Day 1",
            description: "Drive from Dar-es-Salaam to Mikumi National Park (approx. 6 hours) with picnic lunch on your arrival. Go for a Maasai Boma visit at the village till sunset. Dinner and overnight at Camp Bastian (Meal Plan: FB)."
        },
        {
            day: "Day 2",
            description: "Full day game drive at Mikumi National Park. See elephants, zebras, wildebeests, giraffes, impalas, warthogs, hippos, crocodiles, lions, and birds."
        },
        {
            day: "Day 3",
            description: "Half-day game drive at Mikumi National Park, then continue to Selous Game Reserve."
        },
        {
            day: "Day 4",
            description: "Half day game drive at Selous Game Reserve with an afternoon boat safari in the Rufiji River. Overnight at Selous Mloka Lodge."
        },
        {
            day: "Day 5",
            description: "Full day game drive at Selous Game Reserve. Picnic lunch at a selected site. Dinner and overnight at Mloka Lodge."
        },
        {
            day: "Day 6",
            description: "Morning walking safari, followed by breakfast and drive back to Dar es Salaam with packed lunch boxes."
        },
    ],
    inclusions: [
        "Park fee",
        "Boat fee",
        "Island fee",
        "Accommodations",
        "Food and soft drinks",
        "Conservations fee",
        "Guide fee",
        "Safari car",
        "Transfer"
    ],
    exclusions: [
        "Extra service",
        "Personal purchase",
        "Flight ticket"
    ],
    pricing: [
        {
            type: "Price per person",
            price: "USD 2700"
        }
    ],
    faqs: [],
    mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.316207841955!2d37.25626345000001!3d-7.0351275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1831aeffe38d4a35%3A0x6c7d46e7bd780dd2!2sMikumi%20National%20Park!5e0!3m2!1sen!2stz!4v1661780950125"
};


export const serengetiNgorongoroManyaraTarangireSafari = {
    id: "3-days-serengeti-ngorongoro-manyara-tarangire-safari",
    title: "3 Days Serengeti, Ngorongoro, and Manyara/Tarangire National Park",
    duration: "3 days 2 nights",
    locations: [
        "Serengeti National Park",
        "Ngorongoro Conservation Area",
        "Lake Manyara National Park or Tarangire National Park"
    ],
    description: `
        Experience the essence of Tanzania's rich wildlife and diverse landscapes with this 3-day safari adventure. Journey through the vast plains of Serengeti National Park, home to the Great Migration and the Big Five. Explore the unique Ngorongoro Crater, often referred to as the "Eighth Wonder of the World," for its abundant wildlife and stunning vistas. Finish your adventure with a visit to either Lake Manyara National Park, known for its tree-climbing lions and pink flamingos, or Tarangire National Park, famous for its large herds of elephants and majestic baobab trees.
    `,
    itineraryDetails: [
        {
            day: "Day 1",
            title: "Serengeti National Park",
            description: `
                Start your safari adventure with a journey to Serengeti National Park, one of the most renowned wildlife sanctuaries in the world. Enjoy an afternoon game drive across the vast Serengeti plains, home to diverse wildlife, including lions, elephants, leopards, cheetahs, zebras, and wildebeests. The park is particularly famous for hosting the annual Great Migration, a spectacle where millions of wildebeests and zebras move in search of greener pastures. Capture stunning photographs and immerse yourself in the breathtaking landscape. Overnight stay at Serengeti Bandas/Cottage.
            `,
            accommodation: "Serengeti Bandas/Cottage"
        },
        {
            day: "Day 2",
            title: "Ngorongoro Conservation Area",
            description: `
                After breakfast, depart for the Ngorongoro Conservation Area, a UNESCO World Heritage Site known for its stunning volcanic caldera. Descend into the Ngorongoro Crater, the world's largest unbroken, unflooded caldera, for a game drive. The crater floor hosts a rich array of wildlife, including the rare black rhino, elephants, lions, and flamingos along the soda lake. Enjoy a picnic lunch amidst this breathtaking setting, followed by further exploration. Overnight stay at Manyara or Tarangire Cottage/Bandas.
            `,
            accommodation: "Manyara/Tarangire Cottage/Bandas"
        },
        {
            day: "Day 3",
            title: "Lake Manyara or Tarangire National Park",
            description: `
                On your final day, choose between exploring Lake Manyara National Park or Tarangire National Park. Lake Manyara is known for its lush green landscapes, tree-climbing lions, and flocks of pink flamingos that grace its alkaline lake. Alternatively, Tarangire National Park is famous for its dense population of elephants and ancient baobab trees, offering unique scenery and rich wildlife. Enjoy a morning game drive and then head back to your base or onward to your next destination.
            `,
            accommodation: "N/A (Back home)"
        }
    ],
    inclusions: [
        "Park fees",
        "Guide fees",
        "Accommodations",
        "Food and soft drinks"
    ],
    exclusions: [
        "Flights",
        "Tips",
        "Personal purchases"
    ],
    pricing: [
        {
            type: "Price per person",
            price: "700 USD"
        }
    ],
    contactInfo: {
        website: "www.g-familyadventures.com",
        phone: "+255688170104",
        email: "info@g-familyadventures.com"
    },
    faqs: [],
    mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660373.3642294255!2d34.85388265623318!3d-2.334354000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1839c4f5831f462f%3A0x5a0a95a849bf270!2sSerengeti%20National%20Park!5e0!3m2!1sen!2stz!4v1661781328459"
};
