import React, { useState } from 'react';
import { ChevronDownIcon } from 'lucide-react';

const ItineraryDay = ({ day, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mb-4 border-b pb-4">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex justify-between items-center w-full text-left font-medium"
      >
        <span className="text-lg">Day {index + 1}: {day.title}</span>
        <ChevronDownIcon className={`w-5 h-5 transform ${isExpanded ? 'rotate-180' : ''}`} />
      </button>
      {isExpanded && (
        <div className="mt-2 text-gray-600">
          <p className="whitespace-pre-line">{day.description}</p>
        </div>
      )}
    </div>
  );
};

const ItinerarySection = ({ itineraryDetails }) => {
  return (
    <div className="mt-8">
      <h2 className="text-2xl font-semibold mb-4">Itinerary</h2>
      {itineraryDetails.map((day, index) => (
        <ItineraryDay key={index} day={day} index={index} />
      ))}
    </div>
  );
};

export default ItinerarySection;
