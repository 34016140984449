import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FilterSection = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="mb-6">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex justify-between items-center w-full text-lg font-semibold mb-2 focus:outline-none"
      >
        <span>{title}</span>
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isExpanded && <div className="space-y-2">{children}</div>}
    </div>
  );
};

const CheckboxList = ({ items, name, showMore = false }) => {
  const [expanded, setExpanded] = useState(false);
  const visibleItems = showMore && !expanded ? items.slice(0, 4) : items;

  return (
    <ul className="space-y-2">
      {visibleItems.map((item, index) => (
        <li key={index} className="flex items-center justify-between">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name={name}
              value={item.value}
              className="form-checkbox"
            />
            <span>{item.label}</span>
          </label>
          <span className="text-sm text-gray-500">({item.count})</span>
        </li>
      ))}
      {showMore && items.length > 4 && (
        <li>
          <button
            onClick={() => setExpanded(!expanded)}
            className="text-blue-600 hover:underline"
          >
            {expanded ? 'Show less' : `Show all ${items.length}`}
          </button>
        </li>
      )}
    </ul>
  );
};


const ActivitySidebar = () => {
  const activities = [
    { label: 'Boating', value: 'boating', count: 3 },
    { label: 'City Tour', value: 'city-tour', count: 9 },
    { label: 'Culture', value: 'culture', count: 1 },
    { label: 'Cycling', value: 'cycling', count: 6 },
    { label: 'Hiking', value: 'hiking', count: 6 },
    { label: 'Jungle Safari', value: 'jungle-safari', count: 8 },
    { label: 'Paragliding', value: 'paragliding', count: 1 },
  ];


  return (
    <div className="bg-white shadow-lg rounded-lg p-6 w-72">
      <FilterSection title="Activities">
        <CheckboxList items={activities} name="activities" showMore={true} />
      </FilterSection>

    </div>
  );
};

export default ActivitySidebar;
