import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon, MapPin, Clock } from 'lucide-react';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
import ItinerarySection from './ItinerarySection';
import { serengetiNgorongoroManyaraTarangireSafari, zanzibarsGemsItinerary, ngorongoroSafariTour, mikumiAndSelousSafari, gombeAndUgallaSafari, mikumiNationalParkSafari } from '../data/itineraries';

const destinations = [
    {
        title: "Safaris",
        backgroundImage: "/assets/beach3.jpg"
    }
];

const ItineraryDetails = () => {
    const { id } = useParams();
    const [itinerary, setItinerary] = useState(null);
    const [loading, setLoading] = useState(true);
    const [debugInfo, setDebugInfo] = useState('');
    const [expandedSections, setExpandedSections] = useState({});
    const sectionRefs = {
        overview: useRef(null),
        itinerary: useRef(null),
        inclusions: useRef(null),
        pricing: useRef(null),
        faqs: useRef(null),
        map: useRef(null)
    };

    useEffect(() => {
        setLoading(true);
        setDebugInfo(`Received id: ${id}`);

        if (id === '3-days-zanzibar-safari') {
            setItinerary(zanzibarsGemsItinerary);
            setDebugInfo(prev => `${prev}\nMatched zanzibar-gems`);
        } else if (id === '3-days-ngorongoro-safari') {
            setItinerary(ngorongoroSafariTour);
            setDebugInfo(prev => `${prev}\nMatched ngorongoro-safari`);
        } else if (id === '6-days-mikumi-safari') {
            setItinerary(mikumiAndSelousSafari);
            setDebugInfo(prev => `${prev}\nMatched mikumi-selous`);
        } else if (id === '4-days-gombeandugalla-safari') {
            setItinerary(gombeAndUgallaSafari);
            setDebugInfo(prev => `${prev}\nMatched gombe-ugalla`);
        } else if (id === '3-days-mikumi-safari') {
        setItinerary(mikumiNationalParkSafari);
        setDebugInfo(prev => `${prev}\nMatched mikumi-national-park`);
    } else if (id === '3-days-serengeti-ngorongoro-manyara-tarangire-safari') {
        setItinerary(serengetiNgorongoroManyaraTarangireSafari);
        setDebugInfo(prev => `${prev}\nMatched serengeti-ngorongoro-manyara-tarangire`);
    } else {
            setItinerary(null);
            setDebugInfo(prev => `${prev}\nNo match found`);
        }
        setLoading(false);
    }, [id]);

    const toggleSection = (sectionId) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionId]: !prev[sectionId]
        }));
    };

    const scrollToSection = (sectionId) => {
        sectionRefs[sectionId]?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!itinerary) {
        return (
            <div>
                <h2>Itinerary not found</h2>
                <p>Debug Info:</p>
                <pre>{debugInfo}</pre>
            </div>
        );
    }

    return (
        <>
            <Navbar />
            {destinations.map((section, index) => (
                <section
                    key={index}
                    className="relative h-screen bg-cover bg-center"
                    style={{ backgroundImage: `url(${section.backgroundImage})` }}
                >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="container mx-auto px-4">
                        <div className="flex h-screen items-end justify-center">
                            <div className="text-center pb-20 relative z-10">
                                <nav className="flex justify-center text-lg mb-4">
                                    <Link to="/" className="text-gray-300 hover:text-white transition duration-300 ease-in-out">
                                        Home
                                        <ChevronRightIcon className="inline-block w-4 h-4 ml-2 mr-2" />
                                    </Link>
                                    <span className="text-white">
                                        {section.title}
                                        <ChevronRightIcon className="inline-block w-4 h-4 ml-2" />
                                    </span>
                                </nav>
                                <h1 className="text-4xl md:text-5xl font-bold text-white">{section.title}</h1>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
            <div className="max-w-5xl mx-auto p-4">
                <Link to="/safari" className="text-blue-600 hover:underline mb-4 inline-block">
                    &larr; Back to all safaris
                </Link>
                <h1 className="text-3xl font-bold mb-4">{itinerary.title}</h1>
                <div className="mb-4">
                    <span className="mr-2">{itinerary.days}</span>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-semibold mb-2">Trip Facts</h2>
                    <ul className="grid grid-cols-2 gap-4">
                        <li className="flex items-center">
                            <MapPin className="mr-2" />
                            <span>Location: {itinerary.location}</span>
                        </li>
                        <li className="flex items-center">
                            <Clock className="mr-2" />
                            <span>Duration: {itinerary.days}</span>
                        </li>
                    </ul>
                </div>

                <Tab.Group>
                    <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 sticky top-0 z-10">
                        {['Overview', 'Itinerary', 'Inclusions', 'Pricing', 'FAQs', 'Map'].map((category) => (
                            <Tab
                                key={category}
                                className={({ selected }) =>
                                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700
${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
                                }
                                onClick={() => scrollToSection(category.toLowerCase())}
                            >
                                {category}
                            </Tab>
                        ))}
                    </Tab.List>
                </Tab.Group>

                <div className="mt-8">
                    <section ref={sectionRefs.overview} id="overview" className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Overview</h2>
                        <p>{itinerary.description}</p>
                    </section>

                    <section ref={sectionRefs.itinerary} id="itinerary" className="mb-8">
                        <ItinerarySection itineraryDetails={itinerary.itineraryDetails} />
                    </section>

                    <section ref={sectionRefs.inclusions} id="inclusions" className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Inclusions</h2>
                        <ul className="list-disc pl-5">
                            {itinerary.inclusions.map((item, index) => (
                                <li key={index} className="mb-2">{item}</li>
                            ))}
                        </ul>
                        {itinerary.exclusions && (
                            <>
                                <h3 className="text-xl font-semibold mt-4 mb-2">Exclusions</h3>
                                <ul className="list-disc pl-5">
                                    {itinerary.exclusions.map((item, index) => (
                                        <li key={index} className="mb-2">{item}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </section>

                    <section ref={sectionRefs.pricing} id="pricing" className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Pricing</h2>
                        {itinerary.pricing.map((option, index) => (
                            <div key={index} className="mb-4">
                                <h3 className="font-medium">{option.type}</h3>
                                <p className="text-green-600 font-semibold">{option.price}</p>
                            </div>
                        ))}
                    </section>

                    <section ref={sectionRefs.faqs} id="faqs" className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">FAQs</h2>
                        {itinerary.faqs.map((faq, index) => (
                            <div key={index} className="mb-4">
                                <h3 className="font-medium mb-2">{faq.question}</h3>
                                <p>{faq.answer}</p>
                            </div>
                        ))}
                    </section>

                    <section ref={sectionRefs.map} id="map" className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Map</h2>
                        <div className="aspect-w-16 aspect-h-9">
                            <iframe
                                src={itinerary.mapUrl}
                                width="600"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </section>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default ItineraryDetails;
