import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight, Quote } from 'lucide-react';

const CounterItem = ({ endValue, suffix, title }) => {
    const [count, setCount] = useState(0);
    const counterRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    let start = 0;
                    const end = parseInt(endValue);
                    const duration = 2000;
                    const increment = Math.ceil(end / (duration / 16));

                    const timer = setInterval(() => {
                        start += increment;
                        if (start > end) {
                            setCount(end);
                            clearInterval(timer);
                        } else {
                            setCount(start);
                        }
                    }, 16);
                }
            },
            { threshold: 0.5 }
        );

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
        };
    }, [endValue]);

    return (
        <div className="text-center" ref={counterRef}>
            <div className="text-4xl font-bold">
                {count}{suffix}
            </div>
            <div className="text-sm mt-2">{title}</div>
        </div>
    );
};

const TrustedCompany = ({ name, logoUrl }) => (
    <div className="flex flex-col items-center justify-center p-4">
        <img src={logoUrl} alt={`${name} logo`} className="h-12 mb-2" />
        {
            // <p className="text-sm text-center">{name}</p>
        }
    </div>
);

const TestimonialItem = ({ title, content, author, avatarUrl }) => (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl mx-auto text-center">
        <Quote className="mx-auto text-gray-400 mb-4" size={48} />
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600 mb-4 italic">"{content}"</p>
        <div className="flex items-center justify-center">
            {
                // <img src={avatarUrl} alt={author} className="w-12 h-12 rounded-full mr-4" />
            }
            <p className="font-medium">{author}</p>
        </div>
    </div>
);

const AboutTestimonialSection = () => {
    const [currentTestimonial, setCurrentTestimonial] = useState(0);
    const testimonials = [
        {
            title: "An Unforgettable Experience",
            content: "Lorta Tours transformed our travel dreams into reality. The attention to detail, personalized service, and unforgettable experiences exceeded all our expectations. It was a journey we’ll cherish forever!",
            author: "Kelvin Meena",
            avatarUrl: "/api/placeholder/48/48"
        },
        {
            title: "Exceptional Service and Adventure",
            content: "From the moment we booked with Lorta Tours, we knew we were in good hands. Their expert team crafted a perfect itinerary that balanced adventure and relaxation. Truly exceptional service and a trip to remember!",
            author: "James Smith",
            avatarUrl: "/api/placeholder/48/48"
        },
        // Add more testimonials as needed
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="min-h-screen">
            {/* Counter Section */}
            <section className="bg-customBlue text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                        <CounterItem endValue="500" suffix="+" title="TRIPS AND TOURS" />
                        <CounterItem endValue="30" suffix="+" title="COUNTRIES" />
                        <CounterItem endValue="50" suffix="+" title="OUTDOOR ACTIVITIES" />
                        <CounterItem endValue="50000" suffix="+" title="HAPPY NOMADS" />
                    </div>
                </div>
            </section>

            {/* Trusted Companies Section */}
            <section className="py-12">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-2">Trusted by</h2>
                    <p className="text-center text-gray-600 mb-8">With award winning services we are trusted many companies.</p>
                    <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                        <TrustedCompany name="Company 1" logoUrl="/assets/tanapa.webp" />
                        <TrustedCompany name="Company 2" logoUrl="/assets/tanapa.webp" />
                        <TrustedCompany name="Company 3" logoUrl="/assets/tanapa.webp" />
                        <TrustedCompany name="Company 4" logoUrl="/assets/tanapa.webp" />
                        <TrustedCompany name="Company 5" logoUrl="/assets/tanapa.webp" />
                    </div>
                </div>
            </section>

            {/* Testimonials Section */}
            <section className="bg-orange-50 py-12">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-2">Client testimonials</h2>
                    <p className="text-center text-gray-600 mb-8">Real travelers. Real stories. Real opinions to help you make the right choice.</p>
                    <div className="relative">
                        <TestimonialItem {...testimonials[currentTestimonial]} />
                        <button
                            onClick={() => setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length)}
                            className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
                        >
                            <ChevronLeft size={24} />
                        </button>
                        <button
                            onClick={() => setCurrentTestimonial((prev) => (prev + 1) % testimonials.length)}
                            className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
                        >
                            <ChevronRight size={24} />
                        </button>
                    </div>
                    <div className="text-center mt-8">
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AboutTestimonialSection;
