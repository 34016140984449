import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Clock, Users } from 'lucide-react';

const tripData = [
    {
        image: "/assets/animal4.jpg",
        featured: true,
        title: "Paris Effiel Tower Tour 1 Day Tour",
        destinations: ["France", "India", "Nepal", "Srilanka"],
        duration: "5 Hours",
        groupSize: "1-10 People",
        discount: 40,
        price: 30,
        originalPrice: 50,
        description: "Travel is the movement of people between relatively distant geographical locations...",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/paris-effiel-tower-data/"
    },
    {
        image: "/assets/animal3.jpg",
        featured: true,
        title: "Paris Effiel Tower Tour 1 Day Tour",
        destinations: ["France", "India", "Nepal", "Srilanka"],
        duration: "9 Hours",
        groupSize: "1-10 People",
        discount: 50,
        price: 30,
        originalPrice: 50,
        description: "Travel is the movement of people between relatively distant geographical locations...",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/paris-effiel-tower-data/"
    },
    {
        image: "/assets/animal2.jpg",
        featured: true,
        title: "Paris Effiel Tower Tour 1 Day Tour",
        destinations: ["France", "India", "Nepal", "Srilanka"],
        duration: "9 Hours",
        groupSize: "1-10 People",
        discount: 50,
        price: 30,
        originalPrice: 50,
        description: "Travel is the movement of people between relatively distant geographical locations...",
        detailsUrl: "https://lortaexpeditions.3ts.co.tz/data/paris-effiel-tower-data/"
    },
]


const TripCard = ({ trips }) => {
    return (
        <div className="space-y-4">
            {trips.map((destination) => (
                <div key={destination.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                    <div className="flex flex-col sm:flex-row">
                        <div className="relative w-full">
                            <img src={destination.image} alt={destination.title} className="w-full h-full object-cover" />
                            <span className="absolute top-2 right-2 bg-yellow-400 text-white px-2 py-1 rounded">
                                {destination.price}/person
                            </span>
                        </div>
                        <div className="flex-grow p-4">
                            <span className="text-sm text-gray-600">{destination.days}</span>
                            <h2 className="text-xl font-semibold mb-2">{destination.title}</h2>
                            <p className="text-gray-600 text-sm mb-4">{destination.description}</p>
                            <div className="flex items-center text-gray-600 text-sm mb-2">
                                <MapPin size={16} className="mr-1" />
                                <span>{destination.location}</span>
                            </div>
                            <div className="flex justify-between items-center mt-4">
                                {
                                    // <span className="bg-gray-500 text-white px-2 py-1 rounded">
                                    //     {destination.price}/person
                                    // </span>
                                    //
                                }
                                <div className="border-t pt-4 w-full">
                                    <div className="text-sm text-gray-600 mb-2">Available throughout the year:</div>
                                    <div className="flex flex-wrap gap-1 mb-4">
                                        {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month) => (
                                            <span key={month} className="bg-gray-200 text-xs px-2 py-1 rounded">
                                                {month}
                                            </span>
                                        ))}
                                    </div>
                                    <Link
                                        to={`/safari/${destination.id}`}
                                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
                                    >
                                        View Details
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TripCard;

